import React from 'react'
import P5Wrapper from 'react-p5-wrapper'

import styles from './Map.module.css'
import mapImg1 from './arthurs1.png'
import mapImg2 from './nelsonlakes.png'
import mapImg3 from './mtcook.png'

const NUMOFLINES = 10
const LINEGAP = 7
const FRAMERATE = 30

function Map(props) {
  const s = sketch => {
    let img
    let x
    let y = 0

    let mapImages = [mapImg1, mapImg2, mapImg3]

    sketch.preload = () => {
      img = sketch.loadImage(mapImages[props.project])
    }

    sketch.setup = () => {
      sketch.createCanvas(sketch.windowWidth, sketch.windowHeight)
      sketch.background(0)
      img.loadPixels()
      sketch.frameRate(FRAMERATE)
    }

    sketch.draw = () => {
      sketch.background(0)
      sketch.fill(255)
      sketch.noFill()
      sketch.strokeJoin(sketch.ROUND)
      y++
      let line
      for (line = 0; line < NUMOFLINES; line++) {
        sketch.beginShape()
        for (x = 0; x < img.width; x = x + 5) {
          let pixel
          if (y + line * 10 < img.height) {
            pixel = img.get(x, y + line * LINEGAP)
          } else {
            pixel = img.get(x, y)
          }
          let r = pixel[1]

          if (r === 255) r = 254
          if (r === 0) r = 1
          let height = (sketch.height * r) / 256
          let yScaled = sketch.height - height
          let xScaled = (x * window.innerWidth) / img.width
          sketch.vertex(xScaled, yScaled)
          // sketch.strokeWeight(10);
          let grad = 255 / NUMOFLINES
          let c = 255 - line * grad
          sketch.stroke(c)
        }
        sketch.endShape()
      }
      if (y === img.height) y = 0
    }

    sketch.windowResized = () => {
      sketch.resizeCanvas(sketch.windowWidth, sketch.windowHeight)
    }

    sketch.mouseClicked = () => {
      sketch.resizeCanvas(sketch.windowWidth, sketch.windowHeight)
      let imgIndex = props.project < mapImages.length - 1 ? props.project + 1 : 0
      img = sketch.loadImage(mapImages[imgIndex])
      props.onProjectChange(imgIndex)
    }
  }

  return (
    <div className={styles.map}>
      <P5Wrapper sketch={s} />
    </div>
  )
}

export default Map
