import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import Helmet from 'react-helmet'
import styles from './Contact.module.css'
import contact from './Contact.md'

function Contact() {
  const [content, setContent] = useState(null)

  useEffect(() => {
    fetch(contact)
      .then(res => res.text())
      .then(text => setContent(text))
  }, [])

  return (
    <div className={styles.contact}>
      <Helmet
        title="Contact | Richard Flett"
        meta={[{ property: 'og:title', content: 'Contact | Richard Flett' }]}
      />
      <ReactMarkdown source={content} linkTarget="_blank" />
    </div>
  )
}

export default Contact
