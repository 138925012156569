import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import Helmet from 'react-helmet'
import styles from './About.module.css'
import about from './About.md'

function About() {
  const [content, setAbout] = useState('')

  useEffect(() => {
    fetch(about)
      .then(res => res.text())
      .then(text => setAbout(text))
  }, [])

  return (
    <div className={styles.about}>
      <Helmet
        title="About | Richard Flett"
        meta={[{ property: 'og:title', content: 'About | Richard Flett' }]}
      />
      <ReactMarkdown source={content} />
    </div>
  )
}

export default About
