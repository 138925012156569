import React, { useState } from 'react'
import Gallery from '../Gallery/Gallery'
import styles from './MapMenu.module.css'

function MapMenu(props) {
  const [toggle, setToggle] = useState(false)

  function handleClick(toggleState, e) {
    // e.preventDefault()
    e.stopPropagation()
    setToggle(toggleState)
  }

  return (
    <div>
      <Gallery
        project={props.project}
        toggle={toggle}
        onToggleChange={handleClick}
      />
      <div className={styles.mapMenu}>
        <div className={styles.projectNumber}>{props.project + 1}/3</div>
        <button
          className={toggle ? styles.openGalleryActive : styles.openGallery}
          type="button"
          onClick={e => handleClick(!toggle, e)}
        >
          GALLERY
        </button>
      </div>
    </div>
  )
}

export default MapMenu
