import React from 'react'
// import 'lazysizes';
import styles from './Gallery.module.css'

function Gallery(props) {
  let active = props.toggle ? styles.gallery : styles.galleryClosed

  const images = [
    {
        caption: 'Goats Pass, -42.9333853,171.7176613',
      src: '/images/DSC_4577.jpg.1440x2160_q90_upscale.jpg',
      srcset:
        '/images/DSC_4577.jpg.180x180_q90_upscale.jpg 180w, /images/DSC_4577.jpg.750x1125_q90_upscale.jpg 750w, /images/DSC_4577.jpg.1440x2160_q90_upscale.jpg 1440w'
    },
    {
        caption: 'Robert Ridge, -41.8867509,172.768331',
      src: '/images/DSC_6063.jpg.1440x2160_q90_upscale.jpg',
      srcset:
        '/images/DSC_6063.jpg.180x180_q90_upscale.jpg 180w, /images/DSC_6063.jpg.750x1125_q90_upscale.jpg 750w, /images/DSC_6063.jpg.1440x2160_q90_upscale.jpg 1440w'
    },
    {
        caption: 'Mt Cook, -43.7440592,170.3392505',
      src: '/images/DSC_4303_02.jpg.1440x2160_q90_upscale.jpg',
      srcset:
        '/images/DSC_4303_02.jpg.180x180_q90_upscale.jpg 180w, /images/DSC_4303_02.jpg.750x1125_q90_upscale.jpg 750w, /images/DSC_4303_02.jpg.1440x2160_q90_upscale.jpg 1440w'
    }
  ]
  return (
    <div className={active}>
      <img
        className={styles.galleryImg}
        alt="mt cook"
        src={images[props.project].src}
        srcSet={images[props.project].srcset}
        onClick={e => props.onToggleChange(false, e)}
      />
      <div className={styles.caption}>{images[props.project].caption}</div>
    </div>
  )
}

export default Gallery
