import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import styles from './Index.module.css'
import index from './Index.md'

function Index() {
  const [content, setContent] = useState(null)

  useEffect(() => {
    fetch(index)
      .then(res => res.text())
      .then(text => setContent(text))
  }, [])

  return (
    <div className={styles.index}>
      <ReactMarkdown source={content} />
    </div>
  )
}

export default Index
