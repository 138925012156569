import React, {useContext} from 'react';
import {withRouter} from 'react-router-dom';



export const RouterContext = React.createContext({
    match: null,
    location: null,
    history: null,
});

export const useRouterContext = () => useContext(RouterContext);

export const RouterContextProvider = ({children, match, location, history}) => (
    <RouterContext.Provider value={{match, location, history}}>
        {children}
    </RouterContext.Provider>
);

export default withRouter(RouterContextProvider);
