import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import RouterContextProvider, { useRouterContext } from './useRouter'
import { Helmet } from 'react-helmet'
import './App.css'
import About from './About/About'
import Index from './Index/Index'
import Contact from './Contact/Contact'
import Detail from './Detail/Detail'
import Menu from './Menu/Menu'
import Map from './Map/Map'
import MapMenu from './MapMenu/MapMenu'

function Content(props) {
  const { location } = useRouterContext()

  props.onContentChange(location)

  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' }
  })
  return transitions.map(({ item, props, key }) => (
    <animated.div className="content" key={key} style={props}>
      <Switch location={item}>
        <Route path="/" exact component={Index} />
        <Route path="/about/" component={About} />
        <Route path="/contact/" component={Contact} />
        <Route path="/detail/" component={Detail} />
      </Switch>
    </animated.div>
  ))
}

function App() {
  const initialDisable = window.innerWidth < 768 ? true : false
  const [project, setProject] = useState(0)
  const [mapDisabled, setMapDisabled] = useState(initialDisable)

  function handleProjectChange(project) {
    setProject(project)
  }

  function handleContentChange(location) {
    if (window.innerWidth < 768 && location.pathname !== '/') {
      setMapDisabled(true)
    } else if (window.innerWidth < 768) {
      setMapDisabled(false)
    } else if (mapDisabled) {
      setMapDisabled(false)
    }
  }

  let mapBackground
  let mapMenu
  if (mapDisabled) {
    mapBackground = ''
    mapMenu = ''
  } else {
    mapBackground = (
      <Map project={project} onProjectChange={handleProjectChange} />
    )
    mapMenu = (
      <MapMenu project={project} onProjectChange={handleProjectChange} />
    )
  }

  return (
    <Router>
      <RouterContextProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Richard Flett</title>
          <meta name="description" content="Full stack developer for hire." />
        </Helmet>
        <div className="App">
          <header className="header">
            <h1>RICHARDFLETT</h1>
          </header>
          <Menu />
          {mapBackground}
          {mapMenu}
          <Content onContentChange={handleContentChange} />
        </div>
      </RouterContextProvider>
    </Router>
  )
}

export default App
