import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Menu.module.css'

function Menu(props) {
  const [active, toggleActive] = useState(false)
  const className = active
    ? styles.hamburgerSpringActive
    : styles.hamburgerSpring

  const toggle = (e, val) => {
    e.stopPropagation()
    toggleActive(val)
  }

  return (
    <nav className={styles.menu}>
      <button
        className={className}
        type="button"
        onClick={(e) => toggle(e, !active)}
      >
        <span className={styles.hamburgerBox}>
          <span className={styles.hamburgerInner} />
        </span>
      </button>

      <nav>
        <ul
          className={
            active ? styles.menuListActive : styles.menuList
          }
        >
          <li className={styles.menuListItem}>
            <Link className={styles.menuItem} to="/" onClick={(e) => toggle(e, false)}>Home</Link>
          </li>
          <li className={styles.menuListItem}>
            <Link className={styles.menuItem} to="/about/" onClick={(e) => toggle(e, false)}>About</Link>
          </li>
          <li className={styles.menuListItem}>
            <Link className={styles.menuItem} to="/detail/" onClick={(e) => toggle(e, false)}>Detail</Link>
          </li>
          <li className={styles.menuListItem}>
            <Link className={styles.menuItem} to="/contact/" onClick={(e) => toggle(e, false)}>Contact</Link>
          </li>
        </ul>
      </nav>
    </nav>
  )
}

export default Menu
