import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import Helmet from 'react-helmet'
import styles from './Detail.module.css'
import detail from './Detail.md'

function Detail() {
  const [content, setContent] = useState(null)

  useEffect(() => {
    fetch(detail)
      .then(res => res.text())
      .then(text => setContent(text))
  }, [])

  return (
    <div className={styles.detail}>
      <Helmet
        title="Project Details | Richard Flett"
        meta={[{ property: 'og:title', content: 'Project Details | Richard Flett' }]}
      />
      <ReactMarkdown source={content} linkTarget="_blank" />
    </div>
  )
}

export default Detail
